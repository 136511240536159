import { Link } from "react-router-dom";
import "./header.css";
import { Links, header, header_Strings } from "../../../Utils/Constants";
import LogoImg from "../../../assets/img/logo.png";
import MenuIcon from "../../../assets/svg/menu.svg";
import CloseIcon from "../../../assets/img/close.png";
import { useState } from "react";
import Drawer from "react-modern-drawer";
import Credit_App from "../../../assets/pdf/Credit_Application.pdf";
import ACH_App from "../../../assets/pdf/ACH_Form.pdf";
import "react-modern-drawer/dist/index.css";

const Header = ({ activeTab }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDrawer = () => {
    setIsOpen((prevState) => !prevState);
  };
  return (
    <>
      <div className="Header">
        <div className="container">
          <div className="Header_Box">
            <div className="navigation_link_header">
              <Link className="NavLink" to="/">
                <div className="navigation_header_logo_img">
                  <img src={LogoImg} alt="logo" width={"100%"} />
                </div>
              </Link>
              <div className="header_Number_Box">
                <div className="Header_Btn_Box">
                  <div className="header_Phone_Link">
                    <a href={`tel:${Links.Number}`} target="_blank">
                      {header_Strings.Call_US}
                    </a>
                  </div>
                  <div>
                    <a href={Credit_App} target="_blank" style={{ cursor: "pointer" }}>
                      <button className="Header_Btn">{header_Strings.Credit_App}</button>
                    </a>

                    <a href={ACH_App} target="_blank" style={{ cursor: "pointer" }}>
                      <button className="Header_Btn">{header_Strings.Ach_Form}</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="navigation_link_header_list navigation_link_header_part">
            {header.map((item) => {
              return (
                <Link className="NavLink" to={item?.path}>
                  <div
                    className={
                      activeTab === item.title
                        ? "navigation_header_link navigation_header_link_activeTab"
                        : "navigation_header_link"
                    }
                  >
                    {item.title}{" "}
                  </div>
                </Link>
              );
            })}
          </div>

          <div className="navigation_link_header_mini_screen">
            <div className="mini_header">
              <Link className="NavLink" to="/">
                <div className="navigation_header_logo_img">
                  <img src={LogoImg} alt="logo" width={"100%"} />
                </div>
              </Link>
            </div>

            <div className="header_Phone_Link">
              <a href={`tel:${Links.Number}`} target="_blank">
                {header_Strings.Call_US}
              </a>
            </div>
            <div className="header_Btn_Md">
              <button className="Header_Btn">
                <a href={Credit_App} target="_blank">
                  {header_Strings.Credit_App}
                </a>
              </button>

              <button className="Header_Btn">
                <a href={ACH_App} target="_blank">
                  {header_Strings.Ach_Form}
                </a>
              </button>
            </div>
            <button className="dropbtn" onClick={toggleDrawer}>
              <img src={MenuIcon} alt="" className="dropbtn_icon" />
            </button>
          </div>

          <Drawer open={isOpen} onClose={toggleDrawer} direction="left" className="bla bla bla Drawer">
            <div className="close_icon">
              <img src={CloseIcon} alt="" width={"30px"} onClick={toggleDrawer} />
            </div>
            <div className="mini_Header_Btn_Box">
              {header.map((item) => {
                return (
                  <Link className="NavLink" to={item.path}>
                    <div
                      className={
                        activeTab === item.title
                          ? "navigation_header_link_activeTab mini_screen_navigation_header_link"
                          : "mini_screen_navigation_header_link"
                      }
                    >
                      {item?.title}
                    </div>
                  </Link>
                );
              })}
            </div>
          </Drawer>
        </div>
      </div>
    </>
  );
};
export default Header;
